<template>
  <v-menu v-model="menu" offset-y rounded="b-xl" max-width="200">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary" dark fab small>
        <v-icon v-if="menu">mdi-close</v-icon>
        <v-icon v-else>mdi-menu</v-icon>
      </v-btn>
    </template>
    <v-list dark dense id="menu__list">
      <template v-if="!isKiosk">
        <v-subheader>MENU</v-subheader>
        <ListItemGroupMenu />
        <v-divider />
      </template>
      <v-subheader>ACCOUNT</v-subheader>
      <ListItemGroupUser />
    </v-list>
  </v-menu>
</template>
<script>
import { getIsKioskSetting } from "@/helpers";

export default {
  name: "UserMenu",
  components: {
    ListItemGroupUser: () =>
      import("@/components/userOrLogin/ListItemGroupUser.vue"),
    ListItemGroupMenu: () =>
      import("@/components/userOrLogin/ListItemGroupMenu.vue"),
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    isKiosk() {
      return getIsKioskSetting;
    },
  },
};
</script>

<style lang="sass" scoped>
.v-menu__content
  top: 70px !important
  left: auto !important
  right: 20px !important
</style>
